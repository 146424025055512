import { HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'

import { PaginatedData } from '~core/models'
import { ApiService } from '~core/services'

import { Comment, CommentBulkUpdateRequest, CommentListRequest, SentimentType } from '../types'

@Injectable({
  providedIn: 'root',
})
export class CommentsApi {
  #apiService = inject(ApiService)

  toggleBookmark(id: string) {
    return this.#apiService.patch(`/v1.2/comments/${id}/toggle-bookmark`)
  }

  toggleHidden(id: string) {
    return this.#apiService.patch(`/v1.2/comments/${id}/toggle-hidden`)
  }

  changeSentiment(id: string, sentiment: Omit<SentimentType, 'UNCLASSIFIED'>) {
    return this.#apiService.patch(`/v1.2/comments/${id}/sentiment`, { sentiment })
  }

  getCommentThread(id: string) {
    return this.#apiService.get(`/v1.2/comments/${id}/thread`)
  }

  getComments(query: CommentListRequest): Observable<PaginatedData<Comment>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.#apiService.get('/v1.2/comments', params)
  }

  bulkUpdateComments(payload: CommentBulkUpdateRequest) {
    return this.#apiService.patch('/v1.2/comments/bulk', payload)
  }
}
