import { Component } from '@angular/core'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

import { CardComponent } from '~common/ui/card/card.component'

@Component({
  selector: 'sb-comment-item-skeleton',
  standalone: true,
  imports: [CardComponent, NgxSkeletonLoaderModule],
  template: `
    <sb-card>
      <ng-template #title>
        <div class="flex items-center justify-between">
          <div class="w-3/4">
            <ngx-skeleton-loader appearance="line" [theme]="{ 'background-color': '#e2e2e5', margin: 0 }" />
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{ 'background-color': '#e2e2e5', height: '15px', width: '80%' }"
            />
          </div>
          <ngx-skeleton-loader appearance="circle" [theme]="{ 'background-color': '#e2e2e5', margin: 0 }" />
        </div>
      </ng-template>
      <ng-template #content>
        <ngx-skeleton-loader appearance="line" [theme]="{ 'background-color': '#e2e2e5', margin: 0 }" />
        <ngx-skeleton-loader appearance="line" [theme]="{ 'background-color': '#e2e2e5', margin: 0, width: '80%' }" />
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{ 'background-color': '#e2e2e5', 'margin-top': 0, width: '60%' }"
        />
      </ng-template>
    </sb-card>
  `,
  styles: ``,
})
export class CommentItemSkeletonComponent {}
