import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, input } from '@angular/core'

import { Channel } from '~features/projects/models/channel.model'

import { AvatarComponent, AvatarSize } from '../../ui/avatar/avatar.component'
import { ChannelTypeLogoComponent } from '../channel-type-logo/channel-type-logo.component'

@Component({
  selector: 'sb-channel-logo',
  template: `
    @if (channel(); as channel) {
      <sb-avatar [src]="channel.picture || channel.picture_url" [title]="channel.name" [size]="size()">
        @if (!hideType()) {
          <sb-channel-type-logo [type]="channel.type" />
        }
      </sb-avatar>
    }
  `,
  styles: [':host { display: flex; flex-direction: column; justify-content: center; }'],
  standalone: true,
  imports: [AvatarComponent, ChannelTypeLogoComponent],
})
export class ChannelLogoComponent {
  channel = input<Channel>()
  hideType = input(false, { transform: coerceBooleanProperty })
  size = input<AvatarSize>()
}
